/* eslint-disable react-hooks/rules-of-hooks */ // false postitive by react hooks eslint plugin, useDebounce is not a react hook
import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['progress']

  declare readonly hasProgressTarget: boolean
  declare readonly progressTarget: HTMLElement
  declare pollingInterval: number

  connect() {
    this.pollingInterval = 5000
    this.loadProgress()
  }

  loadProgress() {
    const voiceUuid = this.data.get('uuid')

    setInterval(() => {
      fetch(`/voices/${voiceUuid}/progress/`)
        .then((response) => {
          return response.json()
        })
        .catch((e) => console.error(e))
        .then((data) => {
          if (data === 'null' || data === undefined) {
            //@ts-ignore
            this.progressTarget.textContent = 'Pending'
          } else {
            const { percentage } = data
            //@ts-ignore
            this.progressTarget.textContent = `${percentage}%`
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }, this.pollingInterval)
  }
}
