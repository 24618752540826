/* eslint-disable react-hooks/rules-of-hooks */ // false postitive by react hooks eslint plugin, useDebounce is not a react hook
import { ApplicationController } from 'stimulus-use'

const DEFAULT_STATUS = 'Pending'

export default class extends ApplicationController {
  static targets = ['tts', 'sts', 'fill']

  declare readonly hasTtsTarget: boolean
  declare readonly ttsTarget: HTMLElement
  declare readonly hasFillTarget: boolean
  declare readonly fillTarget: HTMLElement
  declare readonly hasStsTarget: boolean
  declare readonly stsTarget: HTMLElement

  declare pollingInterval: number

  connect() {
    this.pollingInterval = 5000
    this.loadProgress()
  }

  loadProgress() {
    const voiceUuid = this.data.get('uuid')

    setInterval(() => {
      fetch(`/voices/${voiceUuid}/progress/`)
        .then((response) => {
          return response.json()
        })
        .catch((e) => console.error(e))
        .then((data) => {
          if (data === 'null' || data === undefined) {
            //@ts-ignore
            this.ttsTarget.textContent = DEFAULT_STATUS
            this.stsTarget.textContent = DEFAULT_STATUS
            this.fillTarget.textContent = DEFAULT_STATUS
          } else {
            const { component_status } = data
            //@ts-ignore
            const ttsStatus = component_status.tts || DEFAULT_STATUS
            const stsStatus = component_status.sts || DEFAULT_STATUS
            const fillStatus = component_status.fill || DEFAULT_STATUS

            this.ttsTarget.textContent = `Text-to-Speech: ${ttsStatus}`
            this.stsTarget.textContent = `Speech-to-Speech: ${stsStatus}`
            this.fillTarget.textContent = `Fill: ${fillStatus}`
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }, this.pollingInterval)
  }
}
