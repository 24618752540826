import { Controller } from '@hotwired/stimulus'
import { createConsumer } from '@rails/actioncable'
import { diffWords } from 'diff'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [
    'uploadStep',
    'editStep',
    'synthesizeStep',
    'originalAudio',
    'transcript',
    'originalTranscript',
    'generatedTranscript',
    'resultAudio',
    'form',
    'modal',
    'fileName',
    'fileInputWrapper',
    'fileInput',
    'defaultFileInput',
    'fileSelectedInput',
    'diffIndicator',
    'playOriginalButton',
    'playGeneratedButton',
    'dropZone',
  ]

  declare readonly uploadStepTarget: HTMLElement
  declare readonly editStepTarget: HTMLElement
  declare readonly synthesizeStepTarget: HTMLElement
  declare readonly originalAudioTarget: HTMLAudioElement
  declare readonly transcriptTarget: HTMLTextAreaElement
  declare readonly resultAudioTarget: HTMLAudioElement
  declare readonly formTarget: HTMLFormElement
  declare readonly modalTarget: HTMLElement
  declare readonly fileNameTarget: HTMLElement
  declare readonly fileInputWrapperTarget: HTMLElement
  declare readonly defaultFileInputTarget: HTMLElement
  declare readonly fileSelectedInputTarget: HTMLElement
  declare readonly diffIndicatorTarget: HTMLElement
  declare readonly originalTranscriptTarget: HTMLElement
  declare readonly generatedTranscriptTarget: HTMLElement
  declare readonly playOriginalButtonTarget: HTMLButtonElement
  declare readonly playGeneratedButtonTarget: HTMLButtonElement
  declare readonly fileInputTarget: HTMLInputElement
  declare readonly dropZoneTarget: HTMLElement

  private channel: any
  private filename: string | null = null
  private audio_url: string | null = null
  private currentAudioEditId: string | null = null
  private originalTranscript: string = ''

  connect(): void {
    console.log('AudioEdit controller connected')
    this.setupWebSocket()
    this.setupAudioEventListeners()
    this.setupDragAndDrop()
  }

  setupWebSocket(): void {
    this.channel = createConsumer().subscriptions.create('AudioEditChannel', {
      connected: () => console.log('Connected to AudioEditChannel'),
      disconnected: () => console.log('Disconnected from AudioEditChannel'),
      received: (data: any) => this.handleWebSocketMessage(data),
    })
  }

  setupDragAndDrop() {
    this.dropZoneTarget.addEventListener('dragenter', this.handleDragEnter.bind(this))
    this.dropZoneTarget.addEventListener('dragleave', this.handleDragLeave.bind(this))
    this.dropZoneTarget.addEventListener('dragover', this.handleDragOver.bind(this))
    this.dropZoneTarget.addEventListener('drop', this.handleDrop.bind(this))
  }

  handleDragEnter(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    this.dropZoneTarget.classList.add('tw-border-green-500', 'tw-bg-green-50')
  }

  handleDragLeave(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    this.dropZoneTarget.classList.remove('tw-border-green-500', 'tw-bg-green-50')
  }

  handleDragOver(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDrop(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    this.dropZoneTarget.classList.remove('tw-border-green-500', 'tw-bg-green-50')

    const files = e.dataTransfer?.files
    if (files && files.length) {
      this.handleFileSelect(files[0])
    }
  }

  handleFileSelect(file: File) {
    if (file.type.startsWith('audio/')) {
      this.checkAudioDuration(file).then((isValid) => {
        if (isValid) {
          this.updateFileInputUI(file.name)
          if (this.fileInputTarget.files instanceof FileList) {
            const newFileList = new DataTransfer()
            newFileList.items.add(file)
            this.fileInputTarget.files = newFileList.files
          }
        } else {
          this.resetFileInput()
          Swal.fire('Error', 'Audio file must be less than 20 seconds in duration.', 'error')
        }
      })
    } else {
      Swal.fire('Error', 'Please select an audio file.', 'error')
    }
  }

  setupAudioEventListeners(): void {
    this.originalAudioTarget.addEventListener('ended', () => this.resetAudioButton(this.playOriginalButtonTarget, 'Original'))
    this.resultAudioTarget.addEventListener('ended', () => this.resetAudioButton(this.playGeneratedButtonTarget, 'Generated'))
  }

  triggerFileInput(event: Event): void {
    event.preventDefault()
    this.fileInputTarget.click()
  }

  goBack(): void {
    this.toggleVisibility(this.synthesizeStepTarget, this.editStepTarget)
    this.stopAllAudio()
    this.transcriptTarget.value = this.generatedTranscriptTarget.textContent || ''
    this.updateDiffIndicator()
  }

  goBackToUpload(event: Event): void {
    event.preventDefault()
    this.toggleVisibility(this.editStepTarget, this.uploadStepTarget)
    this.resetFileInput()
    this.transcriptTarget.value = ''
    this.originalTranscript = ''
    this.updateDiffIndicator()
    this.currentAudioEditId = null
  }

  downloadGeneratedAudio(event: Event): void {
    event.preventDefault()
    const audioUrl = this.resultAudioTarget.src
    if (!audioUrl) {
      Swal.fire('Error', 'No generated audio available for download', 'error')
      return
    }
    this.downloadFile(audioUrl, `generated_audio_${this.currentAudioEditId}.wav`)
  }

  handleFileInputChange(event: Event) {
    const input = event.target as HTMLInputElement
    const file = input.files?.[0]

    if (file) {
      this.handleFileSelect(file)
    } else {
      this.resetFileInput()
    }
  }

  resetFileInput(): void {
    this.defaultFileInputTarget.classList.remove('tw-hidden')
    this.fileSelectedInputTarget.classList.add('tw-hidden')
    this.fileInputWrapperTarget.classList.add('tw-border-gray-300', 'hover:tw-border-gray-400')
    this.fileInputWrapperTarget.classList.remove('tw-border-green-500', 'tw-bg-green-50')
  }

  checkAudioDuration(file: File): Promise<boolean> {
    return new Promise((resolve) => {
      const audio = new Audio()
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration <= 20)
      })
      audio.src = URL.createObjectURL(file)
    })
  }

  playOriginalAudio(): void {
    this.originalAudioTarget.play()
  }

  playGeneratedAudio(): void {
    this.resultAudioTarget.play()
  }

  toggleOriginalAudio(): void {
    this.toggleAudio(this.originalAudioTarget, this.playOriginalButtonTarget, 'Original')
  }

  toggleGeneratedAudio(): void {
    this.toggleAudio(this.resultAudioTarget, this.playGeneratedButtonTarget, 'Generated')
  }

  toggleAudio(audioElement: HTMLAudioElement, buttonElement: HTMLButtonElement, audioType: string): void {
    if (audioElement.paused) {
      this.stopAllAudio()
      audioElement.play().catch((error) => {
        console.error(`Error playing ${audioType} audio:`, error)
        Swal.fire('Error', `Failed to play ${audioType} audio. Please try again.`, 'error')
      })
      buttonElement.innerHTML = this.getStopButtonHTML()
    } else {
      audioElement.pause()
      audioElement.currentTime = 0
      this.resetAudioButton(buttonElement, audioType)
    }
  }

  stopAllAudio(): void {
    ;[this.originalAudioTarget, this.resultAudioTarget].forEach((audio) => {
      audio.pause()
      audio.currentTime = 0
    })
    this.resetAudioButton(this.playOriginalButtonTarget, 'Original')
    this.resetAudioButton(this.playGeneratedButtonTarget, 'Generated')
  }

  resetAudioButton(buttonElement: HTMLButtonElement, audioType: string): void {
    buttonElement.innerHTML = this.getPlayButtonHTML(audioType)
  }

  getPlayButtonHTML(text: string): string {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
      </svg>
      <span>${text} Audio</span>
    `
  }

  getStopButtonHTML(): string {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z" clip-rule="evenodd" />
      </svg>
      <span>Stop</span>
    `
  }

  updateDiffIndicator(): void {
    const currentTranscript = this.transcriptTarget.value
    const differences = diffWords(this.originalTranscript, currentTranscript)

    let additions = 0
    let deletions = 0

    differences.forEach((part) => {
      if (part.added) additions += part.value.split(/\s+/).length
      if (part.removed) deletions += part.value.split(/\s+/).length
    })

    this.diffIndicatorTarget.innerHTML = ''

    if (additions > 0) {
      const additionsSpan = document.createElement('span')
      additionsSpan.textContent = `+${additions}`
      additionsSpan.classList.add('tw-text-green-500')
      this.diffIndicatorTarget.appendChild(additionsSpan)
    }

    if (additions > 0 && deletions > 0) {
      this.diffIndicatorTarget.appendChild(document.createTextNode(' '))
    }

    if (deletions > 0) {
      const deletionsSpan = document.createElement('span')
      deletionsSpan.textContent = `-${deletions}`
      deletionsSpan.classList.add('tw-text-red-500')
      this.diffIndicatorTarget.appendChild(deletionsSpan)
    }
  }

  uploadAudio(event: Event): void {
    event.preventDefault()
    console.log('Uploading audio')

    const formData = new FormData(this.formTarget)
    const file = formData.get('audio_edit[input_audio]') as File
    if (!file || file.size === 0) {
      Swal.fire('Error', 'Please select an audio file', 'error')
      return
    }

    this.openModal()
    fetch(this.formTarget.action, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': this.getCSRFToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => this.handleUploadResponse(data))
      .catch((error) => this.handleError(error, 'Failed to process the audio!'))
  }

  generateAgain(event: Event): void {
    event.preventDefault()
    console.log('Generating audio again')

    const formData = new FormData()
    formData.append('audio_edit[target_transcript]', this.generatedTranscriptTarget.textContent || '')
    formData.append('audio_edit[id]', this.currentAudioEditId || '')

    this.openModal()
    fetch(`/edit/${this.currentAudioEditId}`, {
      method: 'PATCH',
      body: formData,
      headers: {
        'X-CSRF-Token': this.getCSRFToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => this.handleGenerateResponse(data))
      .catch((error) => this.handleError(error, 'Failed to generate audio!'))
  }

  handleWebSocketMessage(data: { action: string; id?: string; transcript?: string; audio_url?: string }): void {
    console.log('Handling WebSocket message:', data)
    switch (data.action) {
      case 'transcription_complete':
        this.handleTranscriptionComplete(data)
        break
      case 'audio_generated':
        this.handleAudioGenerated(data)
        break
      case 'audio_generation_failed':
        this.handleAudioGenerationFailed(data)
        break
      case 'transcription_failed':
        this.handleTranscriptionFailed(data)
        break
    }
  }

  handleTranscriptionComplete(data: { id?: string; transcript?: string }): void {
    if (data.id && data.transcript && this.currentAudioEditId === data.id) {
      this.updateTranscriptUI(data.transcript)
    }
  }

  handleAudioGenerated(data: { id?: string; audio_url?: string }): void {
    if (data.id && this.currentAudioEditId === data.id && data.audio_url) {
      this.updateSynthesizedAudioUI(data.audio_url)
    }
  }

  handleAudioGenerationFailed(data: { id?: string; error?: string }): void {
    if (data.id && this.currentAudioEditId === data.id) {
      this.closeModal()
      Swal.fire('Error', `Audio generation failed: ${data.error}`, 'error')
      // Optionally, reset the UI to the edit step
      this.toggleVisibility(this.synthesizeStepTarget, this.editStepTarget)
    }
  }

  handleTranscriptionFailed(data: { id?: string }): void {
    if (data.id && this.currentAudioEditId === data.id) {
      this.closeModal()
      Swal.fire('Error', 'Transcription failed. Please try uploading the audio again.', 'error')
      // Reset the UI to the upload step
      this.toggleVisibility(this.editStepTarget, this.uploadStepTarget)
      this.resetFileInput()
    }
  }

  updateTranscriptUI(transcript: string): void {
    this.toggleVisibility(this.uploadStepTarget, this.editStepTarget)
    this.transcriptTarget.value = transcript
    this.originalTranscript = transcript

    const form = this.editStepTarget.querySelector('form') as HTMLFormElement
    const idInput = form.querySelector('input[name="audio_edit[id]"]') as HTMLInputElement
    idInput.value = this.currentAudioEditId || ''
    form.action = `/edit/${this.currentAudioEditId}`

    this.setupDiffListener()
    this.closeModal()
    this.originalTranscriptTarget.textContent = transcript
    this.playOriginalButtonTarget.innerHTML = this.getPlayButtonHTML('Original')
  }

  setupDiffListener(): void {
    this.transcriptTarget.addEventListener('input', this.updateDiffIndicator.bind(this))
  }

  updateSynthesizedAudioUI(audioEditUrl: string): void {
    this.toggleVisibility(this.editStepTarget, this.synthesizeStepTarget)
    this.resultAudioTarget.src = audioEditUrl
    this.closeModal()
    this.resetAudioButton(this.playOriginalButtonTarget, 'Original')
    this.resetAudioButton(this.playGeneratedButtonTarget, 'Generated')
    this.resultAudioTarget.load()
  }

  editTranscript(event: Event): void {
    event.preventDefault()
    console.log('Editing transcript')
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)

    const editedTranscript = formData.get('audio_edit[target_transcript]') as string

    this.openModal()
    fetch(form.action, {
      method: 'PATCH',
      body: formData,
      headers: {
        'X-CSRF-Token': this.getCSRFToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => this.handleEditResponse(data, editedTranscript))
      .catch((error) => this.handleError(error, 'Failed to generate audio!'))
  }

  handleUploadResponse(data: any): void {
    if (data.success && data.id) {
      this.currentAudioEditId = data.id
      this.originalAudioTarget.src = data.audio_url
      console.log('Audio uploaded successfully, waiting for transcription')
    } else {
      this.handleError(data.error, 'Failed to process the audio!')
    }
  }

  handleGenerateResponse(data: any): void {
    if (data.success) {
      console.log('Audio generation started')
    } else {
      this.handleError(data.error, 'Failed to generate audio!')
    }
  }

  handleEditResponse(data: any, editedTranscript: string): void {
    if (data.success) {
      console.log('Audio generation started')
      this.updateGeneratedTranscript(editedTranscript)
    } else {
      this.handleError(data.error, 'Failed to generate audio!')
    }
  }

  handleError(error: any, message: string): void {
    console.error('Error:', error)
    this.closeModal()
    Swal.fire('Error', message, 'error')
  }

  updateGeneratedTranscript(transcript: string): void {
    this.generatedTranscriptTarget.textContent = transcript
  }

  openModal(): void {
    console.log('Opening modal')
    document.body.classList.add('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-hidden')
    this.modalTarget.classList.add('tw-flex')
  }

  closeModal(): void {
    console.log('Closing modal')
    document.body.classList.remove('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-flex')
    this.modalTarget.classList.add('tw-hidden')
  }

  getCSRFToken(): string {
    return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
  }

  toggleVisibility(hideElement: HTMLElement, showElement: HTMLElement): void {
    hideElement.classList.add('tw-hidden')
    showElement.classList.remove('tw-hidden')
  }

  downloadFile(url: string, filename: string): void {
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  updateFileInputUI(fileName: string) {
    this.fileNameTarget.textContent = fileName
    this.defaultFileInputTarget.classList.add('tw-hidden')
    this.fileSelectedInputTarget.classList.remove('tw-hidden')
    this.fileInputWrapperTarget.classList.remove('tw-border-gray-300', 'hover:tw-border-gray-400')
    this.fileInputWrapperTarget.classList.add('tw-border-green-500', 'tw-bg-green-50')
  }
}
